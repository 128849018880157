import styled, { css } from 'styled-components';

import { ELLIPSIS_CSS, TextField as TField } from 'components/sharedStyles';

export const EditProfileLink = styled.div`
  font-size: 14px;
  line-height: 1.71;
  color: #017aca;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;
  display: inline-block;
`;

export const TextField = styled(TField)`
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${(props) => (props.error ? '#D1334A' : '#DBDBDB')} !important;
    }
  }
`;

export const ContentWrapper = styled.div<{ editMode?: boolean }>`
  position: relative;
  color: #191919;
  padding: 35px 50px;

  ${(props) => (props.editMode ? 'padding: 0 20px;' : '')}
`;

export const BoxCount = styled.div`
  font-size: 48px;
  font-weight: bold;
  line-height: 1.17;
`;

export const BoxLabel = styled.div`
  width: 127px;
  height: 17px;
  margin: 1px 0 8px 1px;
  font-size: 12px;
  line-height: 1.42;
`;

export const Box = styled.div`
  width: 144px;
  height: 116px;
  margin-right: 24px;
  padding: 23px 2px 11px 14px;
  border-radius: 4px;
  background-color: #efefef;
`;

export const Boxes = styled.div`
  display: flex;
  margin: 33px auto 37px auto;
`;

export const ErrorMsg = styled.div`
  margin-top: 6px;
  margin-left: 19px;
  font-size: 12px;
  line-height: 1.33;
  color: #d1334a;
  visibility: hidden;
`;

export const FieldContainer = styled.div<{ showError: boolean }>`
  ${(props) =>
    props.showError
      ? `
    & > ${ErrorMsg} {
      visibility: visible;
    }

    $ > .field {
      border: 1px solid red;
    }`
      : ''}
`;

export const Row = styled.section`
  display: flex;

  & > * {
    width: 80%;
  }
`;

export const Hr = styled.div`
  border-bottom: 1px solid #efefef;
  margin: 16px 0;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 1.71;
`;

export const TileContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: #191919;
`;

export const Tile = styled.div<{ isSelected: boolean; disabled: boolean }>`
  min-width: 120px;
  min-height: 77px;
  border-radius: 4px;
  background-color: #fbfbfb;
  transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
  user-select: none;
  cursor: pointer;
  margin: 1px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  line-height: 1.7;

  ${(props) =>
    props.isSelected &&
    `
    box-shadow: 0 2px 12px 0 rgba(0, 158, 235, 0.5), inset 0 2px 5px 0 rgba(10, 106, 169, 0.9);
    background-color: #017aca;
    color: white;
  `}

  ${(props) => props.disabled && 'opacity: 0.5; cursor: default;'}
`;

export const TileText = styled.span`
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight[20]};
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #191919;
  margin-top: 38px;
  margin-bottom: 11px;
`;

export const GreenCircle = styled.div`
  position: relative;
  top: 2px;
  width: 14px;
  height: 14px;
  object-fit: contain;
  border: solid 3px #3a9b78;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export const YellowCircle = styled(GreenCircle)`
  border: solid 3px #ddbe24;
`;

export const RedCircle = styled(GreenCircle)`
  border: solid 3px #cf3131;
`;

export const ListContainer = styled.div`
  max-width: 500px;
`;

export const ChipTypeA = styled.span`
  display: inline-block;
  height: 24px;
  padding: 6px 11px 5px 8px;
  border-radius: 2px;
  background-color: #e5e9f6;

  font-size: 12px;
  line-height: 1.33;

  text-transform: capitalize;
  white-space: nowrap;
  margin-right: 11px;
  margin-bottom: 8px;
`;

export const ChipTypeB = styled.span`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 2px;
  border: solid 1px #5f6368;

  font-size: 12px;
  line-height: 1.67;

  text-transform: capitalize;
  margin-right: 11px;

  white-space: nowrap;
  margin-bottom: 8px;
`;

export const SubNavigation = styled.div`
  float: left;
  width: 220px;
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #efefef;
`;

export const SubNavButton = styled.div<{ isActive?: boolean }>`
  padding-left: 14px;
  width: 220px;
  height: 52px;
  font-size: 14px;
  line-height: 52px;
  font-weight: 400;
  cursor: pointer;
  display: flex;

  ${(props) =>
    props.isActive &&
    css`
      border-right: 2px solid ${({ theme }) => theme.color.tfBlue};
      background-color: ${({ theme }) => theme.color.zircon};
      font-weight: 400;
      margin-right: -1.5px;
    `}

  &:hover {
    color: ${({ theme }) => theme.color.tfBlue};
  }
`;

export const SubNavText = styled.div`
  margin: 2px 8px 0;
`;

export const ExpandedContainer = styled.div`
  float: left;
  width: 590px;
  height: 100%;
  z-index: -1;
  overflow: scroll;
`;

export const TeacherEditWrapper = styled(ContentWrapper)`
  padding: 26px 50px;
  max-height: 100%;
`;

export const ButtonWrapper = styled.div`
  text-align: center;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TeacherViewWrapper = styled.div`
  max-height: 100%;
`;

export const GroupHeader = styled.h4`
  font-size: 14px;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const GroupTable = styled.table`
  font-size: 14px;
  margin: 0;
`;

export const GroupTableHeader = styled.th`
  text-align: center;
  padding: 10px 0;
  width: 100px;
  font-weight: normal;
  border-bottom: 1px solid #efefef;
  vertical-align: bottom;
  line-height: 1.19;
`;

export const GroupTableHeaderName = styled(GroupTableHeader)`
  width: 190px;
  text-align: left;
`;

export const GroupTableData = styled.td`
  padding: 10px 0;
  max-width: 190px;
  ${ELLIPSIS_CSS}
`;

export const GroupTableDataCenter = styled(GroupTableData)`
  text-align: center;
  max-width: 100px;
`;

export const GroupTableDataLink = styled(GroupTableDataCenter)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.tfBlue};
`;

export const CloseWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: -10px;
  cursor: pointer;
  z-index: 1;
`;

export const ProfileName = styled.div`
  font-size: 20px;
  font-weight: bold;
  max-width: 300px;
  ${ELLIPSIS_CSS}
`;

export const School = styled.div`
  position: absolute;
  right: 42px;
  top: 0;
  background-color: #003c65;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  height: 29px;
  width: 116px;
  color: #fff;
`;
